export default {
    init() {
        // JavaScript to be fired on the home page
        $('.nieuws__archive__content').infiniteScroll({
            // options
            path: '.navigation .next',
            append: '.block__append',
            history: false,
            hideNav: '.navigation',
            scrollThreshold: false,
            button: '.loadmore',
        });

        $( document ).ready( function($) {
            let $container = $( '.nieuws__archive__content .block__append' ).first().parent();

            if($('.navigation > a').hasClass('page-numbers')) {
                $('.loadmore').show();
            } else {
                $('.loadmore').hide();
            }

            $container.on( 'request.infiniteScroll', function( ) {
                $( '.loadmore' ).addClass('show');
            } );

            $container.on( 'append.infiniteScroll', function(  ) {
                $( '.loadmore' ).removeClass('show');
            } );
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
