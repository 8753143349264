export default {
    init() {
        // JavaScript to be fired on the home page
        if ($(window).width() < 1200) {
            $('.front__proces__items').slick({
                arrows: false,
                swipeToSlide: true,
                dots: false,
                infinite: false,
                variableWidth: true,
                slidesToShow: 1,
            });
        }
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
