export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.slider__dienst').slick({
            infinite: true,
            swipeToSlide: true,
            lazyLoad: 'progressive',
            speed: 200,
            slidesToShow: 3,
            slidesToScroll: 1,
            cssEase: 'ease-out',
            prevArrow: $('.slider__dienst--slider__arrow__prev'),
            nextArrow: $('.slider__dienst--slider__arrow__next'),
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.slider__referenties').slick({
            infinite: true,
            swipeToSlide: true,
            lazyLoad: 'progressive',
            speed: 200,
            slidesToShow: 3,
            slidesToScroll: 1,
            cssEase: 'ease-out',
            prevArrow: $('.slider__referenties--slider__arrow__prev'),
            nextArrow: $('.slider__referenties--slider__arrow__next'),
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        })

        $('.slider__branches').slick({
            infinite: true,
            swipeToSlide: true,
            lazyLoad: 'progressive',
            speed: 200,
            slidesToShow: 3,
            slidesToScroll: 1,
            cssEase: 'ease-out',
            prevArrow: $('.slider__branches--slider__arrow__prev'),
            nextArrow: $('.slider__branches--slider__arrow__next'),
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.nieuws__archive__content').infiniteScroll({
            // options
            path: '.navigation .next',
            append: '.block__append',
            history: false,
            hideNav: '.navigation',
            scrollThreshold: false,
            button: '.loadmore',
        });

        jQuery( document ).ready( function($) {
            let $container = $( '.nieuws__archive__content .block__append' ).first().parent();

            if($('.navigation > a').hasClass('page-numbers')) {
                $('.loadmore').show();
            } else {
                $('.loadmore').hide();
            }

            $container.on( 'request.infiniteScroll', function( ) {
                $( '.loadmore' ).addClass('show');
            } );

            $container.on( 'append.infiniteScroll', function(  ) {
                $( '.loadmore' ).removeClass('show');
            } );
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
