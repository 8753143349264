export const templateReferentiesData = {
    init() {

        // Selecteer het filterelement
        const filter = document.querySelector('.filter__content__lang');

        // Selecteer alle elementen met de klasse .sf-input-select binnen het filterelement
        const filterLists = filter.querySelectorAll('.sf-input-select');

        // Loop door alle filterLists
        filterLists.forEach(filterList => {
            // Verberg alle opties in de lijst
            filterList.querySelectorAll('option').forEach(option => {
                option.style.display = 'none';
            });

            // Laat de eerste optie zien
            const sfItemZero = filterList.querySelector('.sf-item-0');
            sfItemZero.style.display = 'block';
        });

        // Selecteer de div met de optionID
        const optionIDDiv = document.getElementById('data__lang');

        // Split de waarde van het attribuut data-lang in een array van individuele waarden
        const optionIDs = optionIDDiv.dataset.lang.split(' ');

        // Zoek naar alle option-elementen binnen de parent div
        const optionElements = optionIDDiv.parentElement.querySelectorAll('option');

        // Loop door de option-elementen en vergelijk de waarde met de optionIDs-array
        optionElements.forEach(option => {
            // Controleer of de waarde van het option-element overeenkomt met een van de optionIDs
            if (optionIDs.includes(option.value)) {
                // Laat het overeenkomende option-element zien
                option.style.display = 'block';
            }
        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};

